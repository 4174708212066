import React from 'react'
import Img from './responsiveCloudinary'

// import styles from './rowTemplateOne.module.scss'

class Template extends React.Component {
  render() {
    return (
      <section className="row">
        <div>
          <h1>Template Two</h1>
          <div className="columns large-6">
            <Img user="avenue" publicId={this.props.image1} alt="" />
          </div>
          <div className="columns large-6">
            <Img user="avenue" publicId={this.props.image2} alt="" />
          </div>
        </div>
      </section>
    )
  }
}

export default Template
