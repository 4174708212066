import React from 'react'
import OnVisible from 'react-on-visible'

import Img from '../responsiveCloudinary'

import styles from './CSSpinFade.module.scss'

import square from '../../assets/images/square-spacer.png'

class Template extends React.Component {
  render() {
    return (
      <section className={`${styles.block} ${styles[this.props.margin]}`}>
        {this.props.title && (
          <div className="row">
            <div className="columns large-6 large-push-3">
              <h3 className={styles.title}>{this.props.title}</h3>
              <p className={styles.description}>{this.props.description}</p>
            </div>
          </div>
        )}
        <div className={`row ${styles.overflowHidden}`}>
          <div className={`columns large-12`}>
            <div className={styles.mstretch}>
              <OnVisible
                className={styles.stretch}
                visibleClassName={styles.spinIn}
                percent={80}
              >
                <div className={styles.axis}>
                  <img src={square} style={{ width: '100%' }} alt="" />
                  <Img
                    user="avenue"
                    className={styles.tl}
                    publicId={this.props.image1}
                    alt=""
                  />
                  <Img
                    user="avenue"
                    className={styles.br}
                    publicId={this.props.image2}
                    alt=""
                  />
                </div>
                <Img
                  user="avenue"
                  className={styles.background}
                  publicId="widget-mockup_menpii"
                  alt=""
                />
              </OnVisible>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Template
