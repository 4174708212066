import React from 'react'
import Parallax from 'react-scroll-parallax/lib/components/Parallax'

import Img from '../responsiveCloudinary'

import styles from './CSTwoImages.module.scss'
import textBlockStyles from './CSImageBlock.module.scss'

class Template extends React.Component {
  render() {
    let inlineStyle = {
      backgroundColor: this.props.background || null,
      paddingBottom: '1px', // Don't remove
    }

    return (
      <div style={inlineStyle}>
        <section className={`${styles.block}`}>
          {this.props.textPosition === 'top' && (
            <TextBlock
              title={this.props.title}
              description={this.props.description}
              description2={this.props.description2}
              theme={this.props.textTheme}
              textAlign={this.props.textAlign}
            />
          )}

          <div className={`row`}>
            <div
              className={`columns large-12 ${styles.relative} ${
                styles[this.props.variation]
              }`}
            >
              <Parallax offsetYMax="30%" offsetYMin="-30%" tag="figure">
                <Img
                  user="avenue"
                  className={styles.rearImage}
                  publicId={this.props.image1}
                  quality={this.props.quality}
                  alt=""
                />
              </Parallax>
              <div className={styles.frontImageWrapper}>
                <Img
                  className={styles.frontImage}
                  user="avenue"
                  publicId={this.props.image2}
                  quality={this.props.quality}
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}

const TextBlock = ({
  title,
  description,
  description2,
  theme = 'default',
  textAlign,
}) => (
  <div className="columns large-12">
    <div
      className={`${textBlockStyles.textBlock} ${textBlockStyles[theme]} ${textBlockStyles[textAlign]}`}
    >
      {title && <h2>{title}</h2>}
      {description && <p>{description}</p>}
      {description2 && <p>{description2}</p>}
    </div>
  </div>
)

export default Template
