import React from 'react'
import { Link } from 'gatsby'

import Img from '../responsiveCloudinary'

import styles from './relatedCaseStudy.module.scss'

class Template extends React.Component {
  render() {
    const { caseStudy } = this.props

    return (
      <section className={`${styles.block}`}>
        <Link to={caseStudy.fields.slug}>
          <div className={`row ${styles.relative}`}>
            <div className={`columns ${styles.relative}`}>
              <div className={styles.title}>{caseStudy.frontmatter.title}</div>
            </div>
          </div>
          <Img
            user="avenue"
            publicId={caseStudy.frontmatter.headerImage}
            alt=""
          />
        </Link>
      </section>
    )
  }
}

export default Template
