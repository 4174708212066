import React from 'react'
import { media } from '../../utils/style-utils'
import Img from '../responsiveCloudinary'
import styled from 'styled-components'

const Row = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  flex-wrap: wrap;

  @media (min-width: 1600px) {
    max-width: 1480px;
  }
`

const Col = styled.div`
  flex: 0 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  flex-basis: 100%;
  max-width: 100%;

  img {
    box-shadow: 5px 15px 80px 0 rgba(0, 0, 0, 0.15);
  }

  ${media.medium`
    flex-basis: 50%;
    max-width: 50%;
  `}
`

const Text = styled.p`
  text-align: center;
  padding: 0 20px;
  width: 100%;
  ${media.medium`
      margin-bottom: 80px;
   `}

  ${media.large`
    margin-left: 25%;
    margin-right: 25%;
    margin-bottom: 115px;
  `}
`

class Template extends React.Component {
  render() {
    return (
      <Row>
        <Text>{this.props.description}</Text>

        <Col>
          <Img
            user="avenue"
            publicId={this.props.firstImage}
            medium="500px"
            large="700px"
            alt=""
          />
        </Col>
        <Col>
          <Img
            user="avenue"
            publicId={this.props.secondImage}
            medium="500px"
            large="700px"
            alt=""
          />
        </Col>
      </Row>
    )
  }
}

export default Template
