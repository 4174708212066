import React from 'react'
import styled from 'styled-components'
import { ButtonLinkHollow } from '../buttonStyles'
const Block = styled.div`
  text-align: center;
  padding-bottom: 40px;
  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: 1024px) {
    padding-bottom: 160px;
  }
`

export default ({ buttonText, buttonUrl }) => {
  return (
    <Block>
      <ButtonLinkHollow as="a" type="hollow" target="_blank" href={buttonUrl}>
        {buttonText}
      </ButtonLinkHollow>
    </Block>
  )
}
