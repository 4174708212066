import React from 'react'
import Helmet from 'react-helmet'
import Img from '../components/responsiveCloudinary'
import CSBodyCopy from '../components/caseStudies/CSBodyCopy'
import CSTwoImages from '../components/caseStudies/CSTwoImages'
import CSTwoImagesColumns from '../components/caseStudies/CSTwoImagesColumns'
import CSImageBlock from '../components/caseStudies/CSImageBlock'
import CSThreeImages from '../components/caseStudies/CSThreeImages'
import CSSpinFade from '../components/caseStudies/CSSpinFade'
import CSProjectLink from '../components/caseStudies/CSProjectLink'
import rowTemplateTwo from '../components/rowTemplateTwo'
import diagonalBlock from '../components/diagonalBlock'
import RelatedCaseStudy from '../components/caseStudies/relatedCaseStudy'
import GetInTouch from '../components/getInTouch'
import { graphql } from 'gatsby'
import GlobalComponents from '../components/globalcomponents'
import SocialMeta from '../components/socialMeta'
import FullWidthImage from '../components/fullWidthImage'
import HtmlText from '../components/htmlText'
import TestimonialText from '../components/testimonialText'
import DoubleImg from '../components/doubleImg'

import styles from './work.module.scss'

const templates = {
  CSBodyCopy,
  CSTwoImages,
  CSImageBlock,
  CSThreeImages,
  CSSpinFade,
  rowTemplateTwo,
  diagonalBlock,
  CSProjectLink,
  CSTwoImagesColumns,
  FullWidthImage,
  HtmlText,
  TestimonialText,
  DoubleImg,
}

const keyBy = (arr) => {
  let attrs = {}
  arr.forEach(function (pair) {
    attrs[pair.key] = pair.value
  })
  return attrs
}

const renderRowTemplate = ({ id, component, props }, caseStudy) => {
  const Template = templates[component]
  const attrs = keyBy(props, 'key')

  return <Template key={id} {...attrs} html={caseStudy.html} />
}

class CaseStudyTemplate extends React.Component {
  render() {
    const caseStudy = this.props.data.cs
    const { blocks } = caseStudy.frontmatter
    const { related } = this.props.data

    return (
      <GlobalComponents>
        <Helmet>
          <title>{`${caseStudy.frontmatter.title} Case Study`}</title>
        </Helmet>

        <SocialMeta
          twitterImage={`http://res.cloudinary.com/avenue/image/upload/${caseStudy.frontmatter.headerImage}`}
          ogImage={`http://res.cloudinary.com/avenue/image/upload/${caseStudy.frontmatter.headerImage}`}
          ogTitle={`${caseStudy.frontmatter.title} Case Study | Avenue`}
          ogURL={this.props.location.pathname}
          ogDescription={caseStudy.frontmatter.description}
        />
        <CaseStudyHeader caseStudy={caseStudy} />
        {blocks &&
          blocks.map((block, i) => renderRowTemplate(block, caseStudy))}
        <RelatedCaseStudy caseStudy={related} />
        <GetInTouch
          left="Think we might be a good fit for your project?"
          right="Let's have a chat"
          action="/contact"
          cols={[9, 1, 2]}
        />
      </GlobalComponents>
    )
  }
}

const CaseStudyHeader = ({ caseStudy }) => {
  return (
    <header className={styles.header}>
      <div className="row">
        <Img
          className={styles.headerImage}
          user="avenue"
          publicId={caseStudy.frontmatter.headerImage}
          quality="90"
          alt=""
          scaleInOnLoad
        />
        <div className={styles.gradient} />
        <div className="columns large-12">
          <span className={styles.flag}>Our work</span>
          <h1 className={styles.title}>{caseStudy.frontmatter.title}</h1>
        </div>
      </div>
    </header>
  )
}

export default CaseStudyTemplate

export const query = graphql`
  query WorkQuery($slug: String!, $related: String!) {
    cs: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        headerImage
        description
        related
        blocks {
          id
          component
          props {
            key
            value
          }
        }
      }
    }

    related: markdownRemark(fields: { slug: { eq: $related } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        headerImage
      }
    }
  }
`
