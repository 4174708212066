import React from 'react'
import OnVisible from 'react-on-visible'

import Img from '../responsiveCloudinary'

import styles from './CSThreeImages.module.scss'

class Template extends React.Component {
  render() {
    return (
      <section className={`${styles.block} row sectionSpacer`}>
        <div className={styles.title}>{this.props.title}</div>
        <div className="columns large-12">
          <div className={styles.imgWrapper}>
            <OnVisible visibleClassName={styles.fadeIn}>
              <Img
                user="avenue"
                className={styles.rearImage}
                publicId={this.props.rearImage}
                quality={this.props.quality}
                alt=""
              />
            </OnVisible>
            <OnVisible visibleClassName={styles.fadeIn}>
              <Img
                user="avenue"
                className={styles.middleImage}
                publicId={this.props.middleImage}
                quality={this.props.quality}
                alt=""
              />
            </OnVisible>
            <OnVisible visibleClassName={styles.fadeIn}>
              <Img
                user="avenue"
                className={styles.frontImage}
                publicId={this.props.frontImage}
                quality={this.props.quality}
                alt=""
              />
            </OnVisible>
          </div>
        </div>
      </section>
    )
  }
}

export default Template
